import React from "react"
import SubPageTitle from '../components/sub-page-title/subPageTitle';
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionDescription from "../components/section-description/section-description";
export default class NotFoundPage extends React.Component {
  
  render(){
    return <Layout>
      <SEO 
        title="404: Nie znaleziono strony"
        description="Strona nie została znaleziona" 
        url="https://jointoweb.pl/404"
        lang="pl" 
      />
      <div className="section section--brief">
        <div className="section-brief--title section-brief--title--relative">
          <SubPageTitle text={<span><strong>404</strong> Strona nie znaleziona</span>}/>
          <div className="section-text--description">
            <SectionDescription content="Powróć na stronę główną."/>
          </div>
        </div>
      </div>
    </Layout>
  }
}